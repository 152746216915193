import React from 'react'
import style from './notification_icon_style'

const NotificationIcon = ({count, handleClick}) => {
  const className = (count == 0) ? 'fa fa-bell' : `fa fa-bell ${style.notification_badge}`
  return (
    <i
      className={className}
      data-count={count}
      onClick={handleClick} />
  )
}

export default NotificationIcon
