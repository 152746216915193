import React from "react";
import Popover, { ArrowContainer } from 'react-tiny-popover'
import style from './info_popup_style'


class InfoPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isPopoverOpen: false }
  }

  render() {

    return (
        <Popover
          isOpen={this.state.isPopoverOpen}
          position={['top', 'right', 'left', 'bottom']} // preferred position
          onClickOutside={() => this.setState({ isPopoverOpen: false })}
          content={({ position, targetRect, popoverRect }) => (
            <ArrowContainer
              position={position}
              targetRect={targetRect}
              popoverRect={popoverRect}
              arrowColor={'#3f4444'}
              arrowSize={10} >
              <div className={style.container} dangerouslySetInnerHTML={{ __html: this.props.text }} />
            </ArrowContainer>
          )}
        >
        <i className="fas fa-info-circle" onClick={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })} ></i>
        </Popover>
    )
  }
}

export default InfoPopup